export enum MarkerSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export interface AssetCategoryMarker {
  description: string
  color: string
  size: MarkerSize
}

export interface AssetCategory {
  _id: string
  organization: string
  description: string
  marker: AssetCategoryMarker
}

export type AssetCategoryWithCount = AssetCategory & { count?: number }

export const useAssetCategoryStore = defineStore("assetCategory", () => {
  /** Return ALL the categories that the user has permission to see, also the ones not used by any asset */
  const getCategories = async (organization?: string): Promise<Array<AssetCategory>> => {
    return useCrudMethods<AssetCategory>("/asset-categories").readItems({
      pagination: false,
      query: JSON.stringify({ organization }),
      sort: "description",
      select: JSON.stringify({
        description: 1,
        marker: 1,
      }),
    })
  }

  /** Return ALL the categories that exist for an organization, only for use by admins in the settings pages  */
  const getCategoriesForSettings = async (organization: string): Promise<Array<AssetCategory>> => {
    const { data } = await useSamApi<Array<AssetCategory>>(`/asset-categories/all-categories/${organization}`)
    return data
  }

  /** Returns the categories in use by the assets, which the user has permission to see */
  const getUsedCategories = async (): Promise<Array<AssetCategory>> => {
    const result = await useSamApi<Array<AssetCategory>>(`/asset-categories/used`)
    return result.data
  }

  const getOne = async (id: string): Promise<AssetCategory> => {
    return useCrudMethods<AssetCategory>("/asset-categories").readItemById(id)
  }

  const updateOne = async (id: string, category: Partial<AssetCategory>): Promise<AssetCategory> => {
    return useCrudMethods<AssetCategory>("/asset-categories").updateItem(id, category)
  }

  const createOne = async (category: Partial<AssetCategory>): Promise<AssetCategory> => {
    return useCrudMethods<AssetCategory>("/asset-categories").createItem(category)
  }

  const deleteOne = async (id: string): Promise<AssetCategory> => {
    return useCrudMethods<AssetCategory>("/asset-categories").deleteItem(id)
  }

  const getCategoryAssetCount = async (organization?: string): Promise<Array<CountAggregate>> => {
    const { data } = await useSamApi<Array<CountAggregate>>(
      `/asset-categories/asset-count${organization ? `?organization=${organization}` : ""}`,
    )
    return data
  }

  return {
    getCategories,
    getCategoriesForSettings,
    getUsedCategories,

    getOne,
    updateOne,
    createOne,
    deleteOne,

    getCategoryAssetCount,
  }
})
